var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "dashboard-page2"
  }, [_c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardArea', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardAreaFill', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardLine')], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "md": 12,
      "lg": 6
    }
  }, [_c('cardProgress', {
    staticClass: "margin"
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 12
    }
  }, [_c('lineChart', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 12
    }
  }, [_c('bubble', {
    staticClass: "margin"
  })], 1)], 1), _c('Row', {
    attrs: {
      "gutter": 20
    }
  }, [_c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 18
    }
  }, [_c('mapCard', {
    staticClass: "margin"
  })], 1), _c('Col', {
    attrs: {
      "xs": 24,
      "sm": 24,
      "lg": 24,
      "xl": 6
    }
  }, [_c('activity', {
    staticClass: "margin"
  })], 1)], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };