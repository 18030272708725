import cardArea from "./components/cardArea.vue";
import cardAreaFill from "./components/cardAreaFill.vue";
import cardLine from "./components/cardLine.vue";
import cardProgress from "./components/cardProgress.vue";
import lineChart from "./components/line.vue";
import bubble from "./components/bubble.vue";
import mapCard from "./components/mapCard.vue";
import activity from "./components/activity.vue";
export default {
  name: "dashboard-2",
  components: {
    cardArea,
    cardAreaFill,
    cardLine,
    cardProgress,
    lineChart,
    bubble,
    mapCard,
    activity
  },
  data() {
    return {};
  },
  methods: {
    init() {}
  },
  mounted() {
    this.init();
  }
};