var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-area-fill"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("访问量")]), _c('Dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('Icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "type": "ios-more",
      "size": "22"
    }
  }), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', [_vm._v("查看详情")]), _c('DropdownItem', [_vm._v("更多操作")])], 1)], 1)], 1), _c('div', {
    staticClass: "value"
  }, [_c('count-up', {
    attrs: {
      "id": "dashboard2-2",
      "endVal": 8856,
      "countSize": "30px",
      "countWeight": "400"
    }
  })], 1), _c('apexchart', {
    attrs: {
      "type": "area",
      "height": "45",
      "options": _vm.chartOptions,
      "series": _vm.series
    }
  }), _c('div', {
    staticClass: "today"
  }, [_c('span', {
    staticClass: "t"
  }, [_vm._v("今日访问量")]), _c('span', {
    staticClass: "v"
  }, [_vm._v("12,34")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };