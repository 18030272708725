import countUp from "@/views/my-components/widget/countUp.vue";
export default {
  name: "cardProgress",
  components: {
    countUp
  },
  props: {},
  data() {
    return {
      percent: 0
    };
  },
  methods: {
    init() {
      setTimeout(() => {
        this.percent = 65;
      }, 200);
    }
  },
  mounted() {
    this.init();
  }
};