import countUp from "@/views/my-components/widget/countUp.vue";
var zh = require("../../../../libs/zh.json");
export default {
  name: "cardLine",
  components: {
    countUp
  },
  props: {},
  data() {
    return {
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#47a1ff"],
        chart: {
          toolbar: {
            show: false
          },
          sparkline: {
            enabled: true
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        fill: {
          opacity: 1,
          type: "solid"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 0,
          curve: "smooth"
        },
        labels: [...Array(17).keys()].map(n => `2019-11-${n + 1}`),
        yaxis: {
          min: 0
        },
        xaxis: {
          type: "datetime"
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "MMM dd日"
          }
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "销售额",
        data: [7, 5, 4, 2, 4, 7, 5, 6, 5, 9, 6, 3, 1, 5, 3, 6, 5]
      }];
    }
  },
  mounted() {
    this.init();
  }
};