import countUp from "@/views/my-components/widget/countUp.vue";
var zh = require("../../../../libs/zh.json");
export default {
  name: "cardArea",
  components: {
    countUp
  },
  props: {},
  data() {
    return {
      series: [{
        name: "",
        data: []
      }],
      chartOptions: {
        colors: ["#ff455f"],
        chart: {
          id: "dashboard2-1",
          type: "area",
          sparkline: {
            enabled: true
          },
          locales: [zh],
          defaultLocale: "zh"
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          width: 2,
          curve: "straight"
        },
        labels: [...Array(12).keys()].map(n => `2019-11-${n + 1}`),
        yaxis: {
          min: 0
        },
        xaxis: {
          type: "datetime"
        },
        tooltip: {
          enabled: true,
          x: {
            show: true,
            format: "MMM dd日"
          }
        }
      }
    };
  },
  methods: {
    init() {
      this.series = [{
        name: "销售额",
        data: [47, 45, 54, 38, 56, 24, 65, 31, 37, 39, 62, 51]
      }];
    }
  },
  mounted() {
    this.init();
  }
};