var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', {
    staticClass: "card-activity"
  }, [_c('Card', [_c('p', {
    staticClass: "card-title",
    attrs: {
      "slot": "title"
    },
    slot: "title"
  }, [_vm._v("最新事件")]), _c('div', {
    staticClass: "wrap"
  }, [_c('Timeline', _vm._l(_vm.data, function (item, i) {
    return _c('TimelineItem', {
      key: i,
      attrs: {
        "color": item.color
      }
    }, [_c('p', {
      staticClass: "time"
    }, [_vm._v(_vm._s(item.time))]), _c('p', {
      staticClass: "content"
    }, [_vm._v(_vm._s(item.event))])]);
  }), 1)], 1)])], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };