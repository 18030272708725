var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c('div', [_c('Card', {
    staticClass: "card-progress-count"
  }, [_c('div', {
    staticClass: "card-header"
  }, [_c('span', {
    staticClass: "title"
  }, [_vm._v("新增用户")]), _c('Dropdown', {
    attrs: {
      "trigger": "click"
    }
  }, [_c('Icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "type": "ios-more",
      "size": "22"
    }
  }), _c('DropdownMenu', {
    attrs: {
      "slot": "list"
    },
    slot: "list"
  }, [_c('DropdownItem', [_vm._v("查看详情")]), _c('DropdownItem', [_vm._v("更多操作")])], 1)], 1)], 1), _c('div', {
    staticClass: "value"
  }, [_c('countUp', {
    attrs: {
      "id": "dashboard2-4",
      "endVal": 560,
      "countSize": "30px",
      "countWeight": "400"
    }
  })], 1), _c('Progress', {
    staticStyle: {
      "margin": "20px 0 7px 0"
    },
    attrs: {
      "percent": _vm.percent,
      "status": "success",
      "hide-info": ""
    }
  }), _c('div', {
    staticClass: "today"
  }, [_c('span', {
    staticClass: "t"
  }, [_vm._v("总用用户量")]), _c('span', {
    staticClass: "v"
  }, [_vm._v("31,235")])])], 1)], 1);
};
var staticRenderFns = [];
export { render, staticRenderFns };